import { roboto as theme } from "@theme-ui/presets"

const bannerMore = {
  transition: "transform 0.75s ease, opacity 0.75s ease !important",
  transitionDelay: "2.2s !important ",
  transform: "translateY(0) !important",
  border: "none",
  bottom: 0,
  color: "inherit",
  fontSize: ["0.6em", "0.8em", "0.8em"],
  height: "8.5em",
  left: "50%",
  letterSpacing: "0.225em",
  marginLeft: "-8.5em",
  opacity: 1,
  outline: 0,
  pl: "0.225em",
  position: "absolute",
  textAlign: "center",
  textTransform: "uppercase",
  width: "16em",
  zIndex: "1",

  ":after": {
    backgroundImage: 'url("/images/arrow.png")',
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    bottom: "4em",
    content: "''",
    display: "block",
    height: "1.5em",
    left: "50%",
    margin: "0 0 0 -0.75em",
    position: "absolute",
    width: "1.5em",
  },
}

const banner = {
  height: ["53vh", "67vh", "100vh"],
  // height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  cursor: "default",
  overflow: "hidden",
  position: "relative",
  textAlign: "center",
  color: "white",
  // fontSize: ["1em", "1.25em, 1.25em"],
  ":after": {
    pointerEvents: "none",
    transition: "opacity 1s ease-in-out !important",
    transitionDelay: "1.25s !important ",
    content: "''",
    // background: "darkslategray",
    background: "#0066cc",
    display: "block",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0,
  },
  p: {
    letterSpacing: ".225em",
    textTransform: "uppercase",
    fontSize: ["0.75em", "initial", "initial"],
  },
  h2: {
    transform: "scale(1) !important",
    transition: "transform 0.5s ease, opacity 0.5s ease !important",
    display: "inline-block",
    opacity: 1,
    fontSize: ["1em", "2em", "2em"],
    padding: "0.35em 1em",
    position: "relative",
    zIndex: 1,
    ":before, :after": {
      transition: "width 0.85s ease !important",
      transitionDelay: "0.25s !important",
      background: "#fff",
      content: "''",
      display: "block",
      height: "2px",
      position: "absolute",
      width: "100%",
    },
    ":before": {
      top: 0,
      left: 0,
    },
    ":after": {
      bottom: 0,
      right: 0,
    },
  },
}
export default {
  ...theme,
  styles: {
    section: {
      // pt: 8,
    },
  },

  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      textAlign: "center",
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      transition: "all .3s ease-in-out",
      ":hover": {
        transform: "scale(1.10) ",
      },
      img: {
        width: "inherit",
      },
    },
  },

  bannerPicture: {
    backgroundAttachment: ["scroll", "fixed", "fixed"],
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  banner,
  bannerPreload: {
    ...banner,
    h2: {
      ...banner.h2,
      transform: "scale(0.95) !important",
      opacity: 0,
      ":before, :after": { ...banner.h2[":before, :after"], width: 0 },
    },
    ":after": { ...banner[":after"], opacity: 1 },
  },
  bannerMore,
  bannerMorePreload: {
    ...bannerMore,
    transform: "translateY(8.5em) !important",
    opacity: 0,
  },
  aboutTitle: {
    fontSize: ["29px", "44px", "44px"],
    lineHeight: "48px",
    margin: "0 0 5px",
    fontWeight: 900,
    textAlign: ["center", "initial", "initial"],
  },
  navbar: {
    background: "#000",
    width: "100%",
    color: "white",
    position: "fixed",
    // justifyContent: "space-between",
    zIndex: 2,
    p: 2,
    span: {
      display: ["none", "none", "none", "inline"],
      margin: "3px",
    },

    a: {
      color: "background",
      // fontWeight: "bold",
      textDecoration: "none",
      fontWeight: 350,
      position: "relative",
      top: "4px",
      p: 2,
      transition: "all .3s ease-out",
      ":hover": {
        backgroundColor: "#003366",
      },
    },
  },

  skill: {
    color: "rgba(0, 0, 0, 0.87)",
    height: "32px",
    cursor: "default",
    border: "none",
    display: "inline-flex",
    outline: "none",
    padding: 0,
    fontSize: "0.8125rem",
    alignItems: "center",
    whiteSpace: "nowrap",
    borderRadius: "16px",
    justifyContent: "center",
    textDecoration: "none",
    backgroundColor: "#e0e0e0",
    marginBottom: "6px",
    marginRight: "2px",
    span: {
      cursor: "inherit",
      display: "flex",
      alignItems: "center",
      userSelect: "none",
      whiteSpace: "nowrap",
      padding: "15px",
    },
  },
  footer: {
    backgroundColor: "rgba(29,36,42,.9)",
    padding: "1px",
    textAlign: "center",

    ul: {
      cursor: "default",
      listStyle: "none",
      color: "hsla(0,0%,100%,.5)",
    },

    li: {
      display: "inline-block",
      padding: "0 1em 0 0",
    },
    svg: {
      color: "hsla(0,0%,100%,.5)",
      ":hover": {
        color: "#fff",
      },
    },
  },
}
