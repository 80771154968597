// const languages = require("./src/data/languages")
require("@fortawesome/fontawesome-svg-core/styles.css")
const { config } = require("@fortawesome/fontawesome-svg-core")
// import "@fortawesome/fontawesome-svg-core/styles.css"
// import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false

exports.onClientEntry = () => {
  // const userLang = navigator.language.substr(0, 2)
  // if (window.location.pathname === "/") {
  //   window.location.pathname = "/es"
  //   if (languages.langs.includes(userLang)) {
  //     window.location.pathname = `/${userLang}`
  //   } else {
  //     window.location.pathname = `/${languages.defaultLangKey}`
  //   }
  // }
}
